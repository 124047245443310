@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.sectionTitle {
  text-align: center;
  color: #4a4a4a;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.2px;
}

.uploadText{
  color: #fff;
  border: 1px solid #fff;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 34px;
}

.signupNext {
  & h3 {
    color: #1d1d1f;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -1px;
    position: relative;
    margin-bottom: 40px;
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100px;
      height: 4px;
      background: #000;
    }
  }
}

select {
  border-radius: 50px;
  box-shadow: none;
  height: 40px;
  padding: 0 12px;
}

.inputBox {
  margin-bottom: 26px;
  position: relative;
  & input {
    color: #4a4a4a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.305px;
    height: 40px;
  }
  & > div {
    position: absolute;
    top: 100%;
    margin-top: 2px;
    line-height: 20px;
  }
}

.sectionContainer {
  margin-top: 30px;
  & .uploadAvatarWrapper {
    position: relative;
    width: 100%;
    padding: 0px;
    background: #e57552;
    /* border: 1px dashed #d8dce6; */
    text-align: center;
    transition: background 0.3s ease-in-out;
    border-radius: 50%;
    width: 148px;
    height: 147px;
    margin: 0 auto;
    & input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }

    & label {
      font-size: 14px;
      color: #8e8e8e;
      padding: 0;
      font-weight: 400;
    }
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--colorGrey100);
  /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: 148px;
    height: 148px;
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--colorWhite);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.error{
  margin-top: 20px;
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--colorFail);
  border-width: 2px;
}

.avatar {
  width: 100%;
  height: 100%;
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--colorGrey50);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 148px;
    height: 148px;
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
    margin: 0 auto;
  }
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--colorWhite);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--colorGrey100);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--colorGrey300);
  }
}

.logoAbbreviated {
  font-weight: 800;
  font-size: 30px;
  line-height: 48px;
  font-family: inter;
  color: #ffffff;
  padding-top: 35%;
  text-transform: uppercase;
}
