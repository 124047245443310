@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}


.signupNext {
  & h3 {
    color: #1D1D1F;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -1px;
  }
}


select {
  border-radius: 50px;
  box-shadow: none;
  height: 40px;
  padding: 0 12px;
}

.inputBox {
  margin-bottom: 26px;

  & input {
    color: #4A4A4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.305px;
    height: 40px;
  }
  position: relative;
  & > div{
    position: absolute;
    top: 100%;
    line-height: 20px;
    margin-top: 2px;
  }
}


.locationInput {
  flex-wrap: wrap;

  &>div {
    border: none;

    & svg {
      display: none;
    }
  }

  & input {

    &:hover,
    &:focus {
      border-bottom: 1px solid #b8bfd1;
    }
  }
}

.setpsLocation {
  position: relative;
& > div{
  & > div:nth-child(3){
position: absolute;
top: 100%;
line-height: 20px;
margin-top: 2px;

  }
}
  & .locationIcon {
    position: absolute;
    right: 0;
    top: 42px;
    transform: translate(-50%, -50%);

    & svg {
      width: 18px;
    }
  }

  & input {
    height: 40px;
    padding-right: 34px;
  }
}

.radioButtonRow {
  margin-top: 44px;
}